import React from 'react'
import { SEO, Layout } from '../components'
import { Container, Row, Col, Image } from 'react-bootstrap'
import { RichText } from 'prismic-reactjs'
import { graphql } from 'gatsby'
import { LinkResolver } from '../utils/linkResolver'

// Query for the Blog Home content in Prismic
export const query = graphql`
  query OurStoryQuery {
    prismicOurStory {
      data {
        hero_image {
          url
          alt
        }
        title {
          text
        }
        body_copy {
          raw
        }
      }
    }
  }
`

const OurStory = ({ data }) => {
  const ourStoryData = data.prismicOurStory.data
  const { url: imgSrc, alt: imgAlt } = ourStoryData.hero_image
  const title = ourStoryData.title && ourStoryData.title.text
  const bodyCopy = ourStoryData.body_copy && ourStoryData.body_copy.raw

  return (
    <Layout pageInfo={{ pageName: 'our-story' }}>
      <SEO title="Our Story | Huis Shave Ice" />

      <Container className="huis-our-story">
        <Row>
          <Col
            sm={{ span: 10, offset: 1 }}
            md={{ span: 8, offset: 2 }}
            lg={{ span: 6, offset: 3 }}
            className="text-center mt-4 mb-3"
          >
            {imgSrc && (
              <Image
                className="huis-our-story__image"
                src={imgSrc}
                alt={imgAlt}
              ></Image>
            )}
            {title && <h1> {title} </h1>}
            <RichText render={bodyCopy} linkResolver={LinkResolver} />
          </Col>
          <Col
            sm={{ span: 2, offset: 5 }}
            md={{ span: 2, offset: 5 }}
            lg={{ span: 2, offset: 5 }}
            className="text-center"
          ></Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default OurStory
